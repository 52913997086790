import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Flex, Heading } from 'rebass/styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import ProductCard from '../components/product/card'

const CollectionPage = props => {
  const { data } = props
  const { edges: products } = data.allMarkdownRemark

  return (
    <Layout {...props} locale="en">
      <SEO title="Collection" />

      <Section>
        <Container>
          <Heading as="h1" mb={3} fontSize={7}>Collection</Heading>

          {products && (
            <Flex flexWrap="wrap" justifyContent="space-between">
              {products.map(productNode => {
                const product = productNode.node
                const { id, excerpt, frontmatter } = product
                const { slug, sku, title, featuredImage, currency, price, video } = frontmatter

                return (
                  <Flex key={id} width={['100%', '48%', '30%']} mb={4}>
                    <ProductCard
                      slug={`/product/${slug}`}
                      sku={sku}
                      title={title}
                      excerpt={excerpt}
                      image={featuredImage}
                      currency={currency}
                      price={price}
                      video={video}
                    />
                  </Flex>
                )
              })}
            </Flex>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

CollectionPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default CollectionPage

export const pageQuery = graphql`
  query CollectionEnQuery {
    file(relativePath: { eq: "hero-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMarkdownRemark(
      sort: {fields: frontmatter___position, order: ASC},
      filter: {
        frontmatter: { langKey: { eq: "en" }, type: { eq: "product" } }
      },
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 100)
          frontmatter {
            slug
            sku
            title
            currency
            price
            video
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
